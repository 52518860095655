(function ($) {
	let ajax_url            = ilabs.ajax_url;
	let desktop_input       = '#weight, #length, #height, #width, #package_type, .select-package-type .select-list li, #shipping_from, #shipping_to';
	let package_length      = '#length';
	let package_width       = '#width';
	let standard_length     = 20;
	let standard_width      = 10;
	let palette_length      = 120;
	let palette_width       = 80;
	let half_palette_length = 60;
	let half_palette_width  = 80;
	let display_prices      = '#shipping-prices';
	let shipping_form       = 'form[name="calculate_shipping_form"]';
	let view_all_button     = '.view-all';
	let hide_prices_button  = '.hide-prices';
	let input_number        = 'input[type="number"]';
	let calculator;

	$( document ).ready(
		function () {
			$( '.calculator-section .select .select-wrapper' ).click(
				function() {
					$( '.calculator-section .select .select-wrapper' ).not( this ).siblings( '.select-list' ).removeClass( 'show' );
					$( this ).siblings( '.select-list' ).toggleClass( 'show' );
				}
			)

			$( '.calculator-section .select .select-list li' ).click(
				function() {
					let img           = $( this ).children( 'img' ).attr( 'src' );
					let text          = $( this ).children( 'h4' ).text();
					let selectedClass = $( this ).attr( 'data-value' );
					$( this ).parent().parent().siblings( 'select' ).children( 'option').attr( 'selected', false );
					$( this ).parent().parent().siblings( 'select' ).children( 'option[value="' + selectedClass + '"]' ).attr( 'selected', true );
					$( this ).parent().siblings( '.select-wrapper' ).children( 'img.select-icon' ).attr( 'src', img );
					$( this ).parent().siblings( '.select-wrapper' ).children( 'h4' ).text( text );
					$( this ).parent( '.select-list' ).removeClass( 'show' );

					calculator.block_shipping_parameters( selectedClass );
					calculator.calculate_shipping_price();
				}
			)

			if ( $( shipping_form ).length ) {
				calculator.calculate_shipping_price();
			}

			$( desktop_input ).change(
				function() {
					calculator.calculate_shipping_price();
				}
			)

			$( input_number ).click(
				function() {
					$( this ).select();
				}
			)

		}
	);

	calculator = {
		calculate_shipping_price: function () {
			calculator.hide_price();
			$.ajax(
				{
					url: ajax_url,
					data: {
						action: 'display_shipping_prices',
						calculate_shipping_nonce: $( '#calculate_shipping_nonce' ).val(),
						form_data: $( 'form[name="calculate_shipping_form"]' ).serialize(),
					},
					method: 'POST',
					dataType: 'json',
					success: function (data) {
						if ( data.status === 'ok' ) {
							calculator.display_prices( data.shipping_prices );
						} else if ( data.status === 'advert' ) {
							calculator.advertisement( data.advert_data );
							$( '.overlay' ).remove();
						} else {
							calculator.error_prices( data.message );
							$( '.overlay' ).remove();
						}
					}
				}
			)
		},

		block_shipping_parameters: function ( value ) {
			if ( value === 'paleta' ) {
				$( package_length ).val( palette_length ).prop( 'disabled', true );
				$( package_width ).val( palette_width ).prop( 'disabled', true );
			} else if ( value === 'polpaleta' ) {
				$( package_length ).val( half_palette_length ).prop( 'disabled', true );
				$( package_width ).val( half_palette_width ).prop( 'disabled', true );
			} else {
				$( package_length ).val( standard_length ).prop( 'disabled', false );
				$( package_width ).val( standard_width ).prop( 'disabled', false );
			}
		},

		set_price_format: function ( price ) {
			return ( price / 100 ).toFixed( 2 ).replace( '.', ',' ) + ' zł';
		},

		show_price: function () {
			$( '.send-parcels-price' ).css( "display", "inline-block" );
		},

		display_prices: function ( shipping_prices ) {
			$( display_prices ).empty();
			$( '.overlay' ).remove();

			let logo = '';
			let alt  = '';

			$( display_prices ).append( '<p class="prices-label">' + ilabs.labels.compare_prices + '</p>' );

			Object.entries( shipping_prices ).forEach(
				([key, value]) => {
					let visible_desktop_class_name = '';
					let visible_mobile_class_name  = '';

					if ( key < 6 ) {
						visible_desktop_class_name = 'desktop-visible ';
					}
					if ( key < 4 ) {
						visible_mobile_class_name = 'mobile-visible ';
					}

					if ( value.alt ) {
						alt = value.alt;
					}

					logo = '<img src="' + value.logo + '" alt="' + alt + '" width="144" height="75">';

					$( display_prices ).append( '<a href="' + value.url + '" class="' + visible_desktop_class_name + ' ' + visible_mobile_class_name + '"><div data-service-name="' + value.service_name + '">' + logo + '<p class="price-value">' + calculator.set_price_format( value.price ) + '</p></div></a>' );
				}
			);

			$( display_prices ).append( '<span class="view-all hide">' + ilabs.labels.view_all_prices + '</span>' );
			$( display_prices ).append( '<span class="hide-prices hide">' + ilabs.labels.hide_prices + '</span>' );

			if ( shipping_prices.length >= 4 ) {
				let hide_in_desktop_class = '';
				if ( shipping_prices.length < 6 ) {
					hide_in_desktop_class = ' hide-in-desktop';
				}
				$( view_all_button ).removeClass( 'hide' );
				$( view_all_button ).addClass( hide_in_desktop_class );
			}

			$( view_all_button ).on( "click", function() {
				$( '#shipping-prices a' ).addClass( 'desktop-visible mobile-visible' );
				$( this ).addClass( 'hide' );
				$( hide_prices_button ).removeClass( 'hide' );

				$( hide_prices_button ).on( "click", function() {
					$( '#shipping-prices a' ).each(function( index ) {
						if ( index >= 4 ) {
							$( this ).removeClass( 'mobile-visible' );
						}
						if ( index >= 6 ) {
							$( this ).removeClass( 'desktop-visible' );
						}
					});

					$( this ).addClass( 'hide' );
					$( view_all_button ).removeClass( 'hide' );
				});
			});
		},

		advertisement: function ( advert_data ) {
			$( display_prices ).empty();
			if ( advert_data.logo || advert_data.description || advert_data.button_label || advert_data.button_link ) {
				let logo         = advert_data.logo ? advert_data.logo : '';
				let description  = advert_data.description ? advert_data.description : '';
				let button_link  = advert_data.button_link ? advert_data.button_link : '';
				let button_label = advert_data.button_label ? advert_data.button_label : '';

				$( display_prices ).append( '<div class="advert-container"><div class="advert-image">' + logo + '</div><div class="advert-text">' + description + '</div><div class="advert-button"><a href="'+ button_link +'" class="button button-orange">' + button_label + '</a></div></div>' );
			}
		},

		error_prices: function ( message ) {
			$( display_prices ).empty();
			$( display_prices ).append( '<p id="error-prices">' + message + '</p>' );
		},

		hide_price: function () {
			$( '.send-parcels-price' ).css( "display", "none" );
			$( display_prices ).append( '<img src="/app/themes/sendit/src/img/spinner.gif" class="spinner"></img>' );
			$( '.row-calculator__inner' ).append( '<div class="overlay"></div>' );
		},

	}

})( jQuery );
