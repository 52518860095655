(function ($) {
	let ajax_url            = ilabs.ajax_url;
	let ajax_nonce          = ilabs.ajax_nonce;
	let letter_button       = '.alphabet-navigation a';
	let alphabet_navigation = 'ul.alphabet-navigation';
	let branches_list       = '.branches-list';
	let courier_cities;

	$( document ).ready(
		function () {
			$( letter_button ).click(
				function( event ) {
					event.preventDefault();

					$( letter_button ).not( this ).removeClass( "active" );
					$( this ).toggleClass( "active" );

					courier_cities.clear_data();
					courier_cities.search_information();
					courier_cities.get_branches_list(
						$( this ).attr( 'data-tag' ),
						$( alphabet_navigation ).attr( 'data-parent-slug' ),
					);
				}
			);
		}
	);

	courier_cities = {
		get_branches_list: function ( tag_name, parent_slug ) {
			$.ajax(
				{
					url: ajax_url,
					data: {
						action: 'get_branches_list',
						courier_cities_nonce: ajax_nonce,
						tag_name: tag_name,
						parent_slug: parent_slug
					},
					method: 'POST',
					dataType: 'json',
					success: function (data) {
						courier_cities.clear_data();

						if ( data.status === 'ok' ) {
							courier_cities.do_branches_list( data.branches );
						}
					}
				}
			)
		},

		do_branches_list: function ( branches ) {
			if ( branches.length === 0 ) {
				$( branches_list ).append( '<p>Brak miast na wybraną literę.</p>' );
			} else {
				$( branches_list ).append( '<ul class="branches"></ul>' );
				branches.forEach( courier_cities.do_branch_item );
			}
		},

		do_branch_item: function ( item, index, arr ) {
			$( '.branches' ).append( '<li><a href="' + item.url + '">' + item.name + '</a></li>' );
		},

		clear_data: function () {
			$( branches_list ).empty();
		},

		search_information: function () {
			$( branches_list ).append( '<p>Szukam miast...</p>' );
		},

	}

})( jQuery );
