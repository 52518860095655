(function($) {
    $(document).ready(function () {
        //mobile menu
        $( '.header-navbar__mobile' ).click(
            function() {
                $( this ).toggleClass( 'open' );
                $( '.header-navbar__content' ).toggleClass( 'open' );
            }
		),
        //submenu
        $( '.menu-item-has-children>a' ).click(
            function(e) {
                var windowWidth = $( window ).width();
				if ( windowWidth < 1200 ) {
                    e.preventDefault();
                    $( this ).toggleClass( 'open' );
                    $( this ).siblings( '.sub-menu' ).toggleClass( 'open' );
                }
            }
		),
        $( '.menu-item-has-children.standard' ).mouseenter(
            function() {
                var windowWidth = $( window ).width();
				if ( windowWidth >= 1200 ) {
                    $( this ).children( 'a' ).addClass( 'open' );
                    $( this ).children( '.sub-menu' ).addClass( 'open' );
                }
            }
		),
        $( '.menu-item-has-children.standard' ).mouseleave(
            function() {
                var windowWidth = $( window ).width();
				if ( windowWidth >= 1200 ) {
                    $( this ).children( 'a' ).removeClass( 'open' );
                    $( this ).children( '.sub-menu' ).removeClass( 'open' );
                }
            }
		),
        //faq toggle
        $( '.faq__question' ).click(
            function() {
                $( this ).siblings( '.faq__answer' ).slideToggle();
                $( this ).children( '.faq__icon--plus' ).toggleClass( 'hide' );
                $( this ).children( '.faq__icon--minus' ).toggleClass( 'show' );
            }
        ),
        //video
         $( '.video-content' ).each(function( index ) {
            if( $( this ).length ) {
                $( '.video-image' ).on('click', function () {
                    $( this ).siblings( '.video-wrapper' ).find( '.video-content' ).html('<iframe allowfullscreen frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="embed-responsive-item" src="' + $( this ).siblings( '.video-wrapper' ).find( '.video-content' ).data('yt-url') + '?autoplay=1"></iframe>');
                    $( this ).siblings( '.video-wrapper' ).addClass( 'popup' );
                    $( 'body' ).addClass( 'video-popup-open' );
                });
            }
        }), 
        $( '.video-close' ).click(
            function() {
                $( '.video-wrapper' ).removeClass( 'popup' );
                $( '.video-content' ).children('iframe').attr('src', '');
                $( 'body' ).removeClass( 'video-popup-open' );
            }
        )
        //open close category of help center
        $( '.show-help-center-categories' ).click(
            function() {
                $( this ).toggleClass( 'show' );
                $( this ).siblings().children().children().toggleClass( 'show' );
            }
        ),
         //fixed menu
         $( window ).scroll(
            function() {
                if ( $( document ).scrollTop() > 50) {
                    $( '.site-header' ).addClass( 'fixed' );
                }
                else {
                    $( '.site-header' ).removeClass( 'fixed' );
                }
            }
        );

        if (document.getElementById("snt-main-popup") && document.cookie.indexOf( 'snt_main_popup=true' ) === -1) {

            showSenditPopup();

            const expires = new Date();
            expires.setHours(expires.getHours() + 24);
            document.cookie = 'snt_main_popup=true;expires=' + expires.toUTCString() + ';path=/;SameSite=Lax;Secure';
        }

        function showSenditPopup() {
            $( '#snt-main-popup' ).show();

            $( '.close-popup img' ).on('click', function() {
                $( '#snt-main-popup' ).hide();
            });
        }

    })
})( jQuery );

import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);

//Reviews Slider
let reviewsSlider = new Swiper('.reviews-slider', {
    loop: true,
    slidesPerView: 1,
    navigation: {
        nextEl: '.reviews-slider__navigation--next',
        prevEl: '.reviews-slider__navigation--prev',
    },
});